<template>
  <div class="header">
    <h1>{{siteTitle}}</h1>
    <nav class="navigation">
      <ul>
        <li v-if="$route.name === 'Photo'">
          <router-link class="back-btn" to="/">
            <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m19.5078 8.3394h-11.5585l1.1722-1.7419a1.5326 1.5326 0 0 0 -1.2683-2.3931h-.0032a1.67 1.67 0 0 0 -1.3042.6268l-3.6065 4.5088a.8.8 0 0 0 0 .9995l3.6069 4.5085a1.67 1.67 0 0 0 1.3042.6269h.0028a1.5326 1.5326 0 0 0 1.2683-2.393l-1.1725-1.7425h11.5588a6.728 6.728 0 1 1 0 13.456h-3.2715a1.5 1.5 0 0 0 0 3h3.2715a9.728 9.728 0 1 0 0-19.456z"/></svg>
          </router-link>
        </li>
        <li>
          <a class="login-btn" href="https://photodaily-backend.netlify.app/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="512" height="512"><path d="M23,12V8.71A6.72,6.72,0,0,0,16.29,2h-.58A6.72,6.72,0,0,0,9,8.71V12a3,3,0,0,0-3,3v9.3A5.71,5.71,0,0,0,11.7,30h8.6A5.71,5.71,0,0,0,26,24.3V15A3,3,0,0,0,23,12ZM11,8.71A4.71,4.71,0,0,1,15.71,4h.58A4.71,4.71,0,0,1,21,8.71V12H11ZM24,24.3A3.7,3.7,0,0,1,20.3,28H11.7A3.7,3.7,0,0,1,8,24.3V15a1,1,0,0,1,1-1H23a1,1,0,0,1,1,1Z"/><path d="M17,20.72V24a1,1,0,0,1-2,0V20.72a2,2,0,1,1,2,0Z"/></svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import sanity from "../client";

const query = `*[_type == "siteSettings"] {
  title
}`;

export default {
    name: "HeaderView",

    data() {
      return {
        siteTitle: null
      };
    },

    created() {
      this.fetchData();
    },

    methods: {
      
      fetchData() {


        sanity.fetch(query).then(
          (setting) => {

            this.siteTitle = setting[0].title
            // this.loading = false;
            // this.srcImg = photo[0].immagine;
            // this.currentData = this.dateTime(photo[0].data);
            // this.currentDida = photo[0].dida;
            // this.blocks = photo[0].dida;

          },
          (error) => {
            alert(error);
          }
        );
      },
    }
}
</script>

<style lang="scss" scoped>
  .header {
    margin: 0 auto;
    max-width: $container_width;
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    h1 {
      @include font-bold();
      line-height: 1;
      color: $gray;
      font-size: 25px;
      letter-spacing: 1px;
      margin: 0;
      padding: 0;
    }

    .navigation {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        li {
          margin: 0 5px;
          a {
            width: 30px;
            height: 30px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            &.back-btn {
              svg {
                width: 100%;
                height: auto;
                display: block;
                @include transition(0.5s);
              }
              &:hover {
                svg {
                  fill: $secondary-color;
                }
              }
            }
            &.login-btn {
              
              svg {
                width: 100%;
                height: auto;
                display: block;
                stroke-width: 1;
                stroke: $gray;
                @include transition(0.5s);
              }
              &:hover {
                svg {
                  fill: $secondary-color;
                  stroke: $secondary-color;
                }
              }
            }
            
          }
        }
      }
    }
    
  }
</style>